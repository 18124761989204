import { FunctionComponent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import { Checklist } from '@black-bear-energy/black-bear-energy-common'
import { useApiWithIDTokenGranted } from '../../state/modules/common'
import { ChecklistCard } from './ChecklistCard'

export const SiteRFPDetails: FunctionComponent<SiteRFPDetailsProps> = ({
  id,
  name,
}: SiteRFPDetailsProps) => {
  const [checklists, setChecklists] = useState<Checklist[]>([])
  const api = useApiWithIDTokenGranted()

  useEffect(() => {
    const fetchChecklist = async () => {
      if (!api) {
        return
      }

      const response = await api.get<Checklist[]>(`/site-rfps/${id}/checklists`)
      setChecklists(response.data)
    }

    void fetchChecklist()
  }, [id, api])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>{name}</Box>
        <Link to="/document-exchange">Back</Link>
      </Box>
      <Box>
        {checklists.map((checklist) => (
          <ChecklistCard key={checklist.order} checklist={checklist} />
        ))}
      </Box>
    </Box>
  )
}

export interface SiteRFPDetailsProps {
  id: number
  name: string
}
