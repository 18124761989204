import { IDeveloperField } from 'dromo-uploader-react'
import { ITableMessage } from 'dromo-uploader-js'
import {
  MarketSegment,
  validStateValues,
  SiteAction,
  BuildingStatus,
} from '@black-bear-energy/black-bear-energy-common'

const marketSegmentOptions = (
  Object.keys(MarketSegment) as Array<keyof typeof MarketSegment>
).map((key) => {
  if (key === 'Residential') {
    return {
      label: 'Residential (Single Family)',
      value: key,
    }
  }
  return {
    label: MarketSegment[key],
    value: key,
  }
})

const stateOptions = validStateValues.map((state) => ({
  label: state.short,
  value: state.short,
  alternateMatches: [state.full],
}))

const buildingStatusOptions = (
  Object.keys(BuildingStatus) as Array<keyof typeof BuildingStatus>
).map((key) => {
  return {
    label: BuildingStatus[key],
    value: key,
  }
})

// actions available for a site from the upload list that has a potential
// match to an existing record
const uploadMatchedRecordSiteActions = [
  SiteAction.Ignore,
  SiteAction.New,
  SiteAction.Review,
  SiteAction.Update,
]

// actions available for a site from the upload list that does not
// match any existing record
const uploadUnmatchedRecordSiteActions = [
  SiteAction.Ignore,
  SiteAction.New,
  SiteAction.Review,
]

// actions available for a record from the existing db that did not
// match any sites in the upload list
const existingRecordSiteActions = [
  SiteAction.Ignore,
  SiteAction.Sold,
  SiteAction.Review,
]

export const uploadMatchedRecordSiteActionOptions =
  uploadMatchedRecordSiteActions.map((action) => {
    return {
      label: action,
      value: action,
    }
  })

export const uploadUnmatchedRecordSiteActionOptions =
  uploadUnmatchedRecordSiteActions.map((action) => {
    return {
      label: action,
      value: action,
    }
  })

export const existingRecordSiteActionOptions = existingRecordSiteActions.map(
  (action) => {
    return {
      label: action,
      value: action,
    }
  }
)

export const zipCodeRegex = /^((\d{5}(-\d{4})?)|([A-Z]\d[A-Z] \d[A-Z]\d))$/

export const siteActionSchema: IDeveloperField = {
  label: 'Site Action',
  key: 'siteAction',
  type: 'select',
  selectOptions: uploadUnmatchedRecordSiteActionOptions,
  validators: [
    {
      validate: 'regex_exclude',
      regex: SiteAction.Review,
      errorMessage: 'Review this record and choose the correct action',
    },
  ],
}

export const qbRecordIdSchema: IDeveloperField = {
  label: 'QB Record Id',
  key: 'qbRecordId',
  type: ['number', { preset: 'integer' }],
}

export const fieldSchema: IDeveloperField[] = [
  {
    label: 'Acquisition Date',
    key: 'acquireDate',
    type: 'date',
  },
  {
    label: 'Address',
    key: 'address',
    requireMapping: true,
    alternateMatches: [
      'street address',
      'full address',
      'street name + number',
    ],
  },
  {
    label: 'Asset Manager',
    key: 'assetManager',
  },
  {
    label: 'Building Area (sqft)',
    key: 'buildingArea',
    type: ['number', { preset: 'integer' }],
    alternateMatches: ['sq footage'],
  },
  {
    label: 'Building Status',
    key: 'buildingStatus',
    type: 'select',
    selectOptions: buildingStatusOptions,
  },
  {
    label: 'Built Year',
    key: 'buildingBuildYear',
    type: [
      'number',
      {
        displayFormat: { thousandSeparated: false },
        round: 0,
      },
    ],
  },
  {
    label: 'City',
    key: 'city',
    validators: [{ validate: 'require_with', fields: ['state'] }],
  },
  {
    label: 'Client Designation',
    key: 'clientDesignation',
  },
  {
    label: 'County',
    key: 'county',
  },
  {
    label: 'Has Existing PV/Solar?',
    key: 'hasSolar',
    type: 'checkbox',
  },
  {
    label: 'Has Tenants?',
    key: 'hasTenants',
    type: 'checkbox',
  },
  {
    label: 'Investment Fund',
    key: 'clientInvestmentFund',
  },
  {
    label: 'JV Partner',
    key: 'jvPartnerName',
  },
  {
    label: 'Latitude',
    key: 'latitude',
    type: ['number', { displayFormat: { trimMantissa: true, mantissa: 5 } }],
    validators: [{ validate: 'require_with', fields: ['longitude'] }],
  },
  {
    label: 'Longitude',
    key: 'longitude',
    type: ['number', { displayFormat: { trimMantissa: true, mantissa: 5 } }],
    validators: [{ validate: 'require_with', fields: ['latitude'] }],
  },
  {
    label: 'Market Segment - Sector',
    key: 'marketSegment',
    type: 'select',
    selectOptions: marketSegmentOptions,
    alternateMatches: ['asset type'],
  },
  { label: 'Notes', key: 'notes' },
  {
    label: 'Number of Units',
    key: 'unitCount',
    type: ['number', { preset: 'integer' }],
  },
  {
    label: 'Property Manager',
    key: 'propertyManager',
  },
  {
    label: 'Roof Install Year',
    key: 'roofInstallYear',
    type: [
      'number',
      {
        displayFormat: { thousandSeparated: false },
        round: 0,
      },
    ],
  },
  {
    label: 'Roof Type',
    key: 'roofType',
  },
  {
    label: 'Site Name',
    key: 'name',
    alternateMatches: [
      'property name',
      'asset name',
      'property: property name',
    ],
  },
  {
    label: 'State',
    key: 'state',
    type: 'select',
    selectOptions: stateOptions,
    validators: [{ validate: 'require_with', fields: ['city'] }],
  },
  {
    label: 'Zip Code',
    key: 'zipCode',
  },
  // hidden columns
  {
    label: 'Existing Record Id',
    key: 'existingRecordId',
    hidden: true,
  },
  {
    label: 'Row UUID',
    key: 'rowId',
    hidden: true,
  },
  {
    label: 'Is Potentially Sold',
    key: 'maybeSold',
    hidden: true,
  },
  {
    label: 'Is Geocode Valid',
    key: 'isGeocodeValid',
    hidden: true,
  },
]

export const validations: {
  [key: string]: (value: string) => ITableMessage[]
} = {
  acquireDate: (value: string) => validateDate(value),
  address: (value: string) =>
    validateLength(value, 150).concat(validateRequired(value)),
  assetManager: (value: string) => validateLength(value, 100),
  buildingArea: (value: string) => validateNumberMinMax(value, 500, 10000000),
  buildingBuildYear: (value: string) => validateDate(value),
  city: (value: string) =>
    validateLength(value, 150).concat(validateRequired(value)),
  clientDesignation: (value: string) => validateLength(value, 100),
  clientInvestmentFund: (value: string) => validateLength(value, 100),
  county: (value: string) => validateLength(value, 50),
  jvPartnerName: (value: string) => validateLength(value, 150),
  latitude: (value: string) =>
    // valid range for USA and Canada
    validateNumberMinMax(value, 12, 90).concat(validateRequired(value)),
  longitude: (value: string) =>
    // valid range for USA and Canada
    validateNumberMinMax(value, -170, -51).concat(validateRequired(value)),
  name: (value: string) =>
    validateLength(value, 150).concat(validateRequired(value)),
  propertyManager: (value: string) => validateLength(value, 150),
  roofInstallYear: (value: string) => validateDate(value),
  siteAction: (value: string) => validateRequired(value),
  state: (value: string) => validateRequired(value),
  unitCount: (value: string) => validateNumberMinMax(value, 0, 2000),
  zipCode: (value: string) =>
    validateZipcode(value).concat(validateRequired(value)),
}

function validateLength(value: string, maxLength: number): ITableMessage[] {
  if (!value || value.length <= maxLength) {
    return []
  }
  return [
    {
      message: `Length must be at most ${maxLength}`,
      level: 'error',
    },
  ]
}

function validateNumberMinMax(
  value: string,
  min: number,
  max: number
): ITableMessage[] {
  if (!value) {
    return []
  }
  const parsedValue = parseFloat(value)
  if (parsedValue >= min && parsedValue <= max) {
    return []
  }
  return [
    {
      message: `Invalid number. Expecting number between ${min} and ${max}`,
      level: 'error',
    },
  ]
}

function validateDate(value: string): ITableMessage[] {
  if (!value) {
    return []
  }
  const date = new Date(value)
  if (date.getUTCFullYear() < 1800 || date.getFullYear() >= 2100) {
    return [
      { message: 'Must be between 01/01/1800 and 01/01/2100', level: 'error' },
    ]
  }
  return []
}

function validateRequired(value: string): ITableMessage[] {
  if (!value) {
    return [{ message: 'Required', level: 'error' }]
  }
  return []
}

function validateZipcode(value: string): ITableMessage[] {
  if (!value) {
    return []
  }
  if (!value.match(zipCodeRegex)) {
    return [
      {
        message:
          'Zip codes must be in the format of `01234` or `01234-5678` (US) or `A0A 0A0` (Canada)',
        level: 'error',
      },
    ]
  }
  return []
}
