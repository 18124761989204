import { FunctionComponent } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material'
import { SiteRFPSection } from './SiteRFPSection'
import { MetricsContainer } from './MetricsContainer'
import {
  RFPDocumentSummary,
  SiteRFPDocumentSummary,
} from '@black-bear-energy/black-bear-energy-common'

export const CustomerRFPSection: FunctionComponent<CustomerRFPSectionProps> = ({
  rfpDocumentSummary,
}: CustomerRFPSectionProps) => {
  const siteRFPs = rfpDocumentSummary.siteRFPs
  const totals = customerRFPTotals(siteRFPs)

  return (
    <Accordion data-testid="customerRFPSection">
      <AccordionSummary>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box>{rfpDocumentSummary.customerRFPName}</Box>
          <MetricsContainer
            required={{
              numerator: totals.uploadedCounts.required,
              denominator: totals.totalCounts.required,
            }}
            reviewed={{
              numerator: totals.uploadedCounts.reviewed,
              denominator: totals.uploadedCounts.total,
            }}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box>
          {siteRFPs &&
            siteRFPs.map((siteRFP) => (
              <SiteRFPSection
                key={siteRFP.siteRFPId}
                siteRFPDocumentSummary={siteRFP}
              />
            ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export interface CustomerRFPSectionProps {
  rfpDocumentSummary: RFPDocumentSummary
}

interface CustomerRFPTotals {
  uploadedCounts: {
    required: number
    reviewed: number
    total: number
  }
  totalCounts: {
    required: number
  }
}

function customerRFPTotals(
  siteRFPs: SiteRFPDocumentSummary[]
): CustomerRFPTotals {
  const totals = siteRFPs.reduce(
    (acc, siteRFP) => {
      acc.uploadedCounts.required += siteRFP.uploadedCounts.required
      acc.uploadedCounts.reviewed += siteRFP.uploadedCounts.reviewed
      acc.uploadedCounts.total += siteRFP.uploadedCounts.total
      acc.totalCounts.required += siteRFP.totalCounts.required
      return acc
    },
    {
      uploadedCounts: {
        required: 0,
        reviewed: 0,
        total: 0,
      },
      totalCounts: {
        required: 0,
      },
    }
  )
  return totals
}
